import {DELETE_MANY, fetchUtils, UPDATE_MANY, GET_LIST} from "react-admin";
import LocalStorage from "../utils/services/storage";
import {convertDataRequestToHTTP, convertHTTPResponse} from "./common";
import log from '../utils/log';
import qs from "query-string";

export const ACTION_GENERATE = "ACTION_GENERATE";
export const ACTION_RE_GENERATE = "ACTION_RE_GENERATE";
export const ACTION_SEND_MAIL = "ACTION_SEND_MAIL";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
	let customerId = LocalStorage.instance.getCustomerId()
	const customConvertRequest = (type, resource, params) => {
		let url = "";
		let options = {};


		switch (type) {
			case ACTION_GENERATE:
				url = `${apiUrl}/${resource}/v2/generate`;
				options.body = JSON.stringify(params.data);
				options.method = "POST";
				break;
			case ACTION_RE_GENERATE:
				url = `${apiUrl}/${resource}/v2/re-generate`;
				options.body = JSON.stringify(params.data);
				options.method = "POST";
				break;
			case DELETE_MANY:
				url = `${apiUrl}/${resource}/${customerId}`
				options.method =  "DELETE";
				options.body =JSON.stringify(params.data);
				break;
			case GET_LIST:
				const { page, perPage } = params.pagination;
				params.filter = {
					customerId: customerId,
					serviceId: params.filter.serviceId,
					clientId: params.filter.clientId
				}
				url = `${apiUrl}/${resource}?pageNumber=${page - 1}&pageSize=${perPage}&${qs.stringify(params.filter)}`;
				break;
			default:
				let tokenpackId = LocalStorage.instance.getTokenPackId();
				let serviceId = params.filter.serviceId;
				let clientId = params.filter.clientId;
				params.filter = {
					customerId: customerId,
					tokenPackId: tokenpackId,
					serviceId: serviceId,
					clientId: clientId

				}
				let defaultResponse = convertDataRequestToHTTP(
					apiUrl,
					type,
					resource,
					params
				);
				url = defaultResponse.url;
				options = defaultResponse.options;
		}

		return {url, options};
	};

	const customConvertHTTPResponse = (response, type, resource, params) => {
		switch (type) {
			default:
				return convertHTTPResponse(response, type, resource, params);
		}
	};
	/**
	 * @param {string} type Request type, e.g GET_LIST
	 * @param {string} resource Resource name, e.g. "posts"
	 * @param {Object} payload Request parameters. Depends on the request type
	 * @returns {Promise} the Promise for a data response
	 */
	return (type, resource, params) => {
		// simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
		if (type === UPDATE_MANY) {
			return Promise.all(
				params.ids.map((id) =>
					httpClient(`${apiUrl}/${resource}/${id}`, {
						method: "PUT",
						body: JSON.stringify(params.data),
					})
				)
			).then((responses) => ({
				data: responses.map((response) => response.json),
			}));
		}

		const {url, options} = customConvertRequest(type, resource, params);
		return httpClient(url, options)
			.then((response) =>
				customConvertHTTPResponse(response, type, resource, params)
			)
			.catch((e) => {
				log.error("rest-api", resource, type, e);
				const errorJson = e.body;
				if (errorJson && errorJson.messageKey)
					throw new Error(errorJson.messageKey);
				else throw new Error("error.default.internal.server.error");
			});
	};
};
