import {Button as MuiButton} from "@material-ui/core";
import AutoRenewIcon from "@material-ui/icons/Autorenew";
import EmailIcon from '@material-ui/icons/Email';
import React, {createRef, useRef} from 'react';
import {DELETE_MANY, useMutation, useNotify, useRefresh, useUnselectAll} from 'react-admin';
import ConfirmDialog, {ConfirmDialogRef} from "./ConfirmDialog";
import log from '../../utils/log';
import {ACTION_RE_GENERATE, ACTION_SEND_MAIL} from "../../dataProvider/OTPCodeDataProvider";

const OTPBulkActions = (props) => {
    const {customerId, serviceId, clientId, tokenPackId, selectedIds, callback} = props;
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();

    const [sendMail, sendMailState] = useMutation(
        {
            type: ACTION_SEND_MAIL,
            resource: "otp",
            payload: {data: {ids: selectedIds, ...props.data}},
        },
        {
            onSuccess: (response) => {
                log.info("Done", response);
                notify(`${response.data.length} selected OTP code(s) are sent successfully.`);
                unselectAll("otp");
                refresh();
            },
        }
    );

    const [reGenerate, reGenerateState] = useMutation(
        {
            type: ACTION_RE_GENERATE,
            resource: "otp",
            payload: {
                data: {
                    ids: selectedIds,
                    ...props.data,
                }
            },
        },
        {
            onSuccess: (response) => {
                log.info("Done", response);
                notify(`Re-generate ${response.data.length} OTP codes`);
                unselectAll("otp");
                refresh();
            },
        }
    );

    const [deleteMany, deleteState] = useMutation(
        {
            type: DELETE_MANY,
            resource: "otp",
            payload: {
                data: {
                    ids: selectedIds,
                    ...props.data,
                }
            },
        },
        {
            onSuccess: (response) => {
                log.info("Done", response);
                notify(`Deleted ${response.data.deletedCount} OTP codes`);
                unselectAll("otp");
                refresh();
            },
        }
    );



    const confirmDialogRef = createRef<ConfirmDialogRef>()
    const deleteConfirm = createRef<ConfirmDialogRef>()

    return (
        <>
            <MuiButton
                variant="text"
                color="inherit"
                onClick={() => {
                    deleteConfirm?.current?.setContent(
                        "Delete selected user's OTP.",
                        `We are going to delete (${selectedIds.length}) OTP codes.`
                    );
                    deleteConfirm?.current?.show();
                }}
                disabled={sendMailState.loading}
            >
                Delete
            </MuiButton>

            <MuiButton
                variant="text"
                color="primary"
                startIcon={<EmailIcon/>}
                onClick={() => {
                    confirmDialogRef?.current?.setContent(
                        "Send OTP code selected.",
                        `We are going send (${selectedIds.length}) OTP codes.`
                    );
                    confirmDialogRef?.current?.show();
                }}
                disabled={sendMailState.loading}
            >
                Send Mail
            </MuiButton>
            <MuiButton
                variant="text"
                color="default"
                startIcon={<AutoRenewIcon/>}
                onClick={reGenerate}
                disabled={reGenerateState.loading}
            >
                Re-generate
            </MuiButton>
            <ConfirmDialog
                ref={deleteConfirm} okCallback={() => {
                deleteMany()
            }}/>
            <ConfirmDialog ref={confirmDialogRef} okCallback={() => {
                sendMail()
            }}/>
        </>
    );
};

export default OTPBulkActions
