import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import {Link} from "react-router-dom";

export const renderField = ({classes, input, label, type, icon, trans, disabled, meta: {touched, error}, isCheckBox, ...rest}) => (
    !isCheckBox ? (
        <div className="vkey flex-row">
            {icon && <label className="lf--label">
                {icon}
            </label>
            }
            {input.type !== 'textarea' && <input {...input} {...rest} className='lf--input' placeholder={label} type={input.type} disabled={disabled}/> }
            {input.type === 'textarea' && <textarea {...input} {...rest} className='lf--input' placeholder={label} type={input.type} disabled={disabled}>{ rest.value }</textarea> }
        </div>

    ) : (
        <div style={{marginTop: 15, color: '#c3c3c3'}}>
            <Checkbox {...input} classes={classes}
                      {...rest}
            />
            <label>{trans('resources.signUp.agree_message')}
            </label>
            <a href="/docs/intro/terms-of-use/terms-of-use" target="_blank" style={{color: '#c3c3c3', marginLeft: 3, marginRight: 5}}>
                <i className="ri-upload-2-line pe-2"></i> {trans('resources.signUp.term_of_use')}
            </a>
            <label>and</label>
            <a href="/docs/intro/privacy-policy/privacy-policy" target="_blank" style={{color: '#c3c3c3', marginLeft: 3}}>
                <i className="ri-upload-2-line pe-2"></i> {trans('resources.signUp.privacy_policy')}.
            </a>
            {/*<Link*/}
            {/*    href={"https://www.v-key.com/solutions/terms/"}*/}
            {/*    target="_blank"*/}
            {/*    style={{color: '#c3c3c3', marginLeft: 5 }}>*/}
            {/*    {trans('resources.signUp.term_of_use')}.*/}
            {/*</Link>*/}
        </div>
    )

)
